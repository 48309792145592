import NotFoundDesktop from '../../components/DesktopComponents/NotFoundDesktop/NotFoundDesktop';
import NotFoundMobile from '../../components/MobileComponents/NotFoundMobile/NotFoundMobile';

const deviceWidth = window.innerWidth;

function NotFound() {
    return deviceWidth > 768 ? <NotFoundDesktop /> : <NotFoundMobile />;
}

export default NotFound;
