import styles from './PortfolioMobile.module.css';
import Mobile from '../Mobile/Mobile';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PortfolioMobile() {
    const navigate = useNavigate();

    // import portfolio.json
    const portfolio = require('../../../assets/json/portfolio.json').portfolio;

    const content = () => {
        return (
            <>
                <div className={styles.folders}>
                    {portfolio.map((item: any, index: number) => {
                        return (
                            <div key={index} className={styles.folder}>
                                <div
                                    onClick={() => {
                                        window.open(item.link, '_blank');
                                    }}
                                    className={styles.innerFolder}
                                >
                                    <img src='./assets/images/folder.png' />
                                    <div className={styles.folderName}>
                                        {item.title}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    useEffect(() => {
        setTimeout(() => {
            var parent = document.getElementById(
                'mobileContent'
            ) as HTMLElement;

            var windowBg = document.getElementById('windowBg') as HTMLElement;

            if (!parent || !windowBg) return;

            let childHeight = windowBg.clientHeight;

            childHeight += window.innerWidth * 0.18;

            parent.style.height = childHeight + 'px';
        }, 500);
    }, []);

    return <Mobile address={'Portfolio'} content={content()} />;
}

export default PortfolioMobile;
