import styles from './Contact.module.css';
import ContactDesktop from '../../components/DesktopComponents/ContactDesktop/ContactDesktop';
import ContactMobile from '../../components/MobileComponents/ContactMobile/ContactMobile';

const deviceWidth = window.innerWidth;

function Contact() {
    return deviceWidth > 768 ? <ContactDesktop /> : <ContactMobile />;
}

export default Contact;
