import styles from './HomeDesktop.module.css';
import Desktop from '../Desktop/Desktop';
import Folders from '../Folders/Folders';

import { useNavigate } from 'react-router-dom';

function HomeDesktop() {
    const navigate = useNavigate();

    const content = () => {
        return (
            <>
                <Folders />

                <div className={styles.heroImages}>
                    <div
                        onClick={() =>
                            window.open(
                                'https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=0&ie=utf8&query=%EB%9E%9C%EB%8D%A4+%EB%8B%A4%EC%9D%B4%EB%B2%84%EC%8B%9C%ED%8B%B0',
                                '_blank'
                            )
                        }
                        className={styles.popUpScreen}
                    >
                        <img src='assets/images/pop-up-screen-no-text.png' />
                        <div className={styles.popUpScreenTitle}>
                            What is <span>RANDOM DIVERSITY?</span>
                        </div>
                        <div className={styles.popUpScreenButton}>FIND OUT</div>
                    </div>
                    <div className={styles.vertical}>
                        <img src='assets/images/no-text.png' />
                        <div className={styles.innerText}>
                            <div className={styles.rotatedText}>
                                랜덤 다이버시티 개발사
                            </div>
                        </div>
                    </div>

                    <div className={styles.devs}>
                        <img src='assets/images/scissor-frame.png' />
                        <div className={styles.devDetails}>
                            <div className={styles.devDetail}>
                                <p
                                    style={{
                                        paddingRight: 'calc(100vw * 0.004)',
                                    }}
                                >
                                    소프트웨어
                                </p>
                                <div className={styles.line}></div>
                            </div>
                            <div className={styles.devDetail}>
                                <div className={styles.line}></div>
                                <p style={{ padding: '0 calc(100vw * 0.004)' }}>
                                    하드웨어
                                </p>
                                <div className={styles.line}></div>
                            </div>
                            <div className={styles.devDetail}>
                                <div className={styles.line}></div>
                                <p
                                    style={{
                                        paddingLeft: 'calc(100vw * 0.004)',
                                    }}
                                >
                                    디자인
                                </p>
                            </div>
                            <div className={styles.devDetail}>
                                <div className={styles.line}></div>
                                <p style={{ padding: '0 calc(100vw * 0.004)' }}>
                                    모두 인하우스 제작
                                </p>
                                <div className={styles.line}></div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.companyName}>
                        <div className={styles.enlargeFrame}>
                            <img src='assets/images/enlarge-frame.png' />
                        </div>
                        <div className={styles.logo}>
                            <img src='assets/images/logo.png' />
                        </div>
                        <div className={styles.companyNameText}>Spade 6</div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <Desktop
            address={
                'DEVELOPER OF RANDOM DIVERSITY, RANDOM DIVERSITY: FRAGRANCE, RANDOM DESTINY, EMOTION THEME PARK'
            }
            content={content()}
        />
    );
}

export default HomeDesktop;
