import './App.css';
import HomeDesktop from './components/DesktopComponents/HomeDesktop/HomeDesktop';
import HomeMobile from './components/MobileComponents/HomeMobile/HomeMobile';

const deviceWidth = window.innerWidth;
function App() {
    return deviceWidth > 768 ? <HomeDesktop /> : <HomeMobile />;
}

export default App;
