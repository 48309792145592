import styles from './Folders.module.css';
import { useNavigate } from 'react-router-dom';

function Folders() {
    const navigate = useNavigate();

    return (
        <div className={`buttons`}>
            <div className={styles.folders}>
                <div
                    onClick={() => navigate('/portfolio')}
                    className={styles.folder}
                >
                    <img src='./assets/images/folder.png' />
                    <div className={styles.folderName}>Portfolio</div>
                </div>

                <div
                    style={{ marginTop: 'calc(100vw * 0.028)' }}
                    className={styles.folder}
                    onClick={() => navigate('/partner')}
                >
                    <img src='./assets/images/folder.png' />
                    <div className={styles.folderName}>Partner</div>
                </div>

                <div
                    style={{ marginTop: 'calc(100vw * 0.028)' }}
                    className={styles.folder}
                    onClick={() => navigate('/about')}
                >
                    <img src='./assets/images/documents.png' />
                    <div className={styles.folderName}>About</div>
                </div>

                <div
                    className={styles.contactButton}
                    style={{ marginTop: '5.2vw' }}
                    onClick={() => navigate('/contact')}
                >
                    <img src='assets/images/colorful-button.png' />
                    <div>CONTACT US</div>
                </div>
            </div>
        </div>
    );
}

export default Folders;
