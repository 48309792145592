import styles from './NavBar.module.css';
import { useNavigate } from 'react-router-dom';

function NavBar() {
    const navigate = useNavigate();

    return (
        <div className={styles.navBar}>
            <div className={styles.navBarMenu}>
                <div
                    onClick={() => navigate('/')}
                    style={{ paddingLeft: 'calc(100vw * 0.02)' }}
                >
                    Home
                </div>
                <div onClick={() => navigate('/portfolio')}>Portfolio</div>
                <div onClick={() => navigate('/partner')}>Partner</div>
                <div onClick={() => navigate('/about')}>About</div>
                <div onClick={() => navigate('/contact')}>Contact</div>
            </div>
            <div className={`${styles.navBarMenu} ${styles.partners}`}>
                <div
                    onClick={() => {
                        window.open(
                            'https://www.instagram.com/ran.dom.diver.sity/',
                            '_blank'
                        );
                    }}
                >
                    Discrete Label
                </div>
                <div
                    onClick={() => {
                        window.open(
                            'https://www.instagram.com/cheon.che/',
                            '_blank'
                        );
                    }}
                >
                    Cheon Che
                </div>
            </div>
        </div>
    );
}

export default NavBar;
