import styles from './NotFoundDesktop.module.css';
import Desktop from '../Desktop/Desktop';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Folders from '../Folders/Folders';

function NotFoundDesktop() {
    const navigate = useNavigate();

    const content = () => {
        return (
            <>
                <Folders />

                <div className={styles.notFoundFrame}>
                    <img src='assets/images/not-found.png' />
                    <div className={styles.notFoundText}>
                        We could not find what you’ve been looking for.
                        <br />
                        Click button to see more interesting stuff!
                    </div>
                    <div
                        onClick={() => {
                            navigate('/');
                        }}
                        className={styles.button}
                    >
                        Go Home
                    </div>
                </div>
            </>
        );
    };
    return <Desktop address={'404 NOT FOUND'} content={content()} />;
}

export default NotFoundDesktop;
