import styles from './Mobile.module.css';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function Mobile(props: any) {
    return (
        <div className={styles.mobile}>
            <div className={styles.topBar}>
                <div className={styles.topBarRect}></div>
                <div className={styles.address}>
                    <div className={styles.addressRect}></div>
                    <div className={styles.addressText}>{props.address}</div>
                </div>
                <div className={styles.cancelButton}>
                    <img src='assets/images/cancel-button.png' />
                </div>
            </div>
            <div id='mobileContent' className={styles.mobileContent}>
                <div className={styles.windowSideFrame}></div>
                <div id='windowBg' className={styles.windowBg}>
                    {props.content}
                    <div className={styles.bottomBar}>
                        <div className={styles.bottomBarFrame}></div>
                        <div className={styles.bottomBarRect}>
                            <div className={styles.bottomBarMenu}>
                                <div
                                    onClick={() => {
                                        window.open(
                                            'https://www.instagram.com/ran.dom.diver.sity/',
                                            '_blank'
                                        );
                                    }}
                                >
                                    Discrete Label
                                </div>
                                <div
                                    onClick={() => {
                                        window.open(
                                            'https://www.instagram.com/cheon.che/',
                                            '_blank'
                                        );
                                    }}
                                >
                                    Cheon Che
                                </div>
                            </div>
                        </div>
                        <div className={styles.bottomBarFrame}></div>
                    </div>
                </div>
                <div className={styles.windowSideFrame}></div>
            </div>
        </div>
    );
}

export default Mobile;

Mobile.propTypes = {
    content: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
    ]),
    address: PropTypes.string.isRequired,
};
