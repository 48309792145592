import styles from './AboutMobile.module.css';
import Mobile from '../Mobile/Mobile';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function AboutMobile() {
    const navigate = useNavigate();

    const [language, setLanguage] = useState('korean');
    const about = require('../../../assets/json/about.json').about;
    const [aboutContent, setAboutContent] = useState(about[language]);

    useEffect(() => {
        changeLangue(language);
    }, []);

    const reloadBg = (lang: string) => {
        setTimeout(() => {
            var parent = document.getElementById(
                'mobileContent'
            ) as HTMLElement;

            var windowBg = document.getElementById(
                'aboutContent'
            ) as HTMLElement;

            if (!parent || !windowBg) return;

            let childHeight = windowBg.clientHeight;

            childHeight += window.innerWidth * 0.395;

            parent.style.height = childHeight + 'px';
        }, 500);
    };

    const changeLangue = (lang: string) => {
        setLanguage(lang);
        reloadBg(lang);

        setAboutContent(about[lang]);
        if (lang === 'korean') {
            document.getElementById('english')?.classList.add(styles.show);
            document.getElementById('english')?.classList.remove(styles.hidden);
            document.getElementById('korean')?.classList.remove(styles.show);
            document.getElementById('korean')?.classList.add(styles.hidden);
        } else {
            document.getElementById('english')?.classList.remove(styles.show);
            document.getElementById('english')?.classList.add(styles.hidden);
            document.getElementById('korean')?.classList.add(styles.show);
            document.getElementById('korean')?.classList.remove(styles.hidden);
        }
    };

    const content = () => {
        return (
            <>
                <div id='aboutContent' className={styles.content}>
                    <div className={styles.aboutContent}>
                        {aboutContent.map((item: any, index: number) => {
                            return (
                                <div
                                    key={index}
                                    className={`${item.type} ${
                                        language === 'korean'
                                            ? 'korean-font'
                                            : 'english-font'
                                    }`}
                                >
                                    {item.content}
                                </div>
                            );
                        })}
                    </div>
                    <div
                        onClick={() => {
                            changeLangue(
                                language === 'korean' ? 'english' : 'korean'
                            );
                        }}
                        className={styles.languageChangeButton}
                    >
                        <div id='korean' className={styles.korean}>
                            한글
                        </div>
                        <div id='english' className={styles.english}>
                            Abc
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return <Mobile address={'About'} content={content()} />;
}

export default AboutMobile;
