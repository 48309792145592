import styles from './PartnerDesktop.module.css';
import Desktop from '../Desktop/Desktop';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Folders from '../Folders/Folders';

function PartnerDesktop() {
    const navigate = useNavigate();

    const content = () => {
        return (
            <>
                <Folders />
                <div className={styles.planetFrame}>
                    <img src='assets/images/planet-frame.png' />
                    <div className={styles.planetFrameTitle}>
                        Partners of Spade 6
                    </div>
                    <div className={styles.partners}>
                        <div>
                            <div className={styles.partnerName}>
                                🤝 Discrete Label
                            </div>
                            <div className={styles.partnerRole}>
                                - Immersive Exhibition Agency
                            </div>
                            <div
                                onClick={() => {
                                    window.open(
                                        'https://www.instagram.com/ran.dom.diver.sity/',
                                        '_blank'
                                    );
                                }}
                                className={styles.partnerLink}
                            >
                                <img src='assets/images/insta.png' />
                                @ran.dom.diver.sity
                            </div>
                        </div>
                        <div style={{ marginTop: 'calc(100vw * 0.015)' }}>
                            <div className={styles.partnerName}>
                                🤝 Cheon Che
                            </div>
                            <div className={styles.partnerRole}>
                                - Artist of Discrete Label
                            </div>
                            <div
                                className={styles.partnerLink}
                                onClick={() => {
                                    window.open(
                                        'https://www.instagram.com/cheon.che/',
                                        '_blank'
                                    );
                                }}
                            >
                                <img src='assets/images/insta.png' />
                                @cheon.che
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return <Desktop address={'Partner'} content={content()} />;
}

export default PartnerDesktop;
