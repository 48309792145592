import styles from './ContactDesktop.module.css';
import Desktop from '../Desktop/Desktop';
import Folders from '../Folders/Folders';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

function ContactDesktop() {
    const navigate = useNavigate();
    const [language, setLanguage] = useState('korean');
    const contact = require('../../../assets/json/contact.json').contact;
    const [contactContent, setContactContent] = useState(contact[language]);

    useEffect(() => {
        changeLangue(language);
    }, []);

    const changeLangue = (lang: string) => {
        console.log(lang);
        setLanguage(lang);
        setContactContent(contact[lang]);
        if (lang === 'korean') {
            document.getElementById('english')?.classList.add(styles.show);
            document.getElementById('english')?.classList.remove(styles.hidden);
            document.getElementById('korean')?.classList.remove(styles.show);
            document.getElementById('korean')?.classList.add(styles.hidden);
        } else {
            document.getElementById('english')?.classList.remove(styles.show);
            document.getElementById('english')?.classList.add(styles.hidden);
            document.getElementById('korean')?.classList.add(styles.show);
            document.getElementById('korean')?.classList.remove(styles.hidden);
        }
    };

    const content = () => {
        return (
            <>
                <Folders />

                <div className={styles.gradientFrame}>
                    <div className={styles.contactContent}>
                        {contactContent.map((item: any, index: number) => {
                            return (
                                <div
                                    key={index}
                                    className={`${item.type} ${
                                        language === 'korean'
                                            ? 'korean-font'
                                            : 'english-font'
                                    }`}
                                >
                                    {item.content}
                                </div>
                            );
                        })}

                        <div
                            style={{ marginTop: 'calc(100vw * 0.03)' }}
                            className={styles.contactInfo}
                        >
                            <div className={styles.infoTitle}>Email</div>
                            <div className={styles.infoContent}>
                                dobby@spade6.dev
                            </div>
                        </div>

                        <div
                            style={{ marginTop: 'calc(100vw * 0.03' }}
                            className={styles.contactInfo}
                        >
                            <div className={styles.infoTitle}>Based in</div>
                            <div className={styles.infoContent}>
                                Daejeon, South Korea
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            changeLangue(
                                language === 'korean' ? 'english' : 'korean'
                            );
                        }}
                        className={styles.languageChangeButton}
                    >
                        <div id='korean' className={styles.korean}>
                            한글
                        </div>
                        <div id='english' className={styles.english}>
                            Abc
                        </div>
                    </div>
                    <img src='assets/images/gradient-frame.png' />
                </div>
            </>
        );
    };
    return <Desktop address='Contact' content={content()} />;
}

export default ContactDesktop;
