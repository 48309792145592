import styles from './Portfolio.module.css';
import PortfolioDesktop from '../../components/DesktopComponents/PortfolioDesktop/PortfolioDesktop';
import PortfolioMobile from '../../components/MobileComponents/PortfolioMobile/PortfolioMobile';

const deviceWidth = window.innerWidth;

function Portfolio() {
    return deviceWidth > 768 ? <PortfolioDesktop /> : <PortfolioMobile />;
}

export default Portfolio;
