import styles from './About.module.css';
import AboutDesktop from '../../components/DesktopComponents/AboutDesktop/AboutDesktop';
import AboutMobile from '../../components/MobileComponents/AboutMobile/AboutMobile';

const deviceWidth = window.innerWidth;

function About() {
    return deviceWidth > 768 ? <AboutDesktop /> : <AboutMobile />;
}

export default About;
