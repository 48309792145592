import styles from './PortfolioDesktop.module.css';
import Desktop from '../Desktop/Desktop';
import { useNavigate } from 'react-router-dom';

function PortfolioDesktop() {
    const navigate = useNavigate();

    // import portfolio.json
    const portfolio = require('../../../assets/json/portfolio.json').portfolio;

    const content = () => {
        return (
            <>
                <div className={styles.folders}>
                    {portfolio.map((item: any, index: number) => {
                        return (
                            <div key={index} className={styles.folder}>
                                <div
                                    onClick={() => {
                                        window.open(item.link, '_blank');
                                    }}
                                    className={styles.innerFolder}
                                >
                                    <img src='./assets/images/folder.png' />
                                    <div className={styles.folderName}>
                                        {item.title}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    return <Desktop address={'Portfolio'} content={content()} />;
}

export default PortfolioDesktop;
