import styles from './PartnerMobile.module.css';
import Mobile from '../Mobile/Mobile';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

function PartnerMobile() {
    const navigate = useNavigate();
    const folderMargin = 0.06;

    useEffect(() => {
        setTimeout(() => {
            var parent = document.getElementById(
                'mobileContent'
            ) as HTMLElement;

            var windowBg = document.getElementById('windowBg') as HTMLElement;

            if (!parent || !windowBg) return;

            let childHeight = windowBg.clientHeight;

            childHeight += window.innerWidth * 0.18;

            parent.style.height = childHeight + 'px';
        }, 500);
    }, []);

    const content = () => {
        return (
            <>
                <div className={styles.planetFrame}>
                    <img src='assets/images/notepad-frame.png' />
                    <div className={styles.planetFrameTitle}>
                        Partners of Spade 6
                    </div>
                    <div className={styles.partners}>
                        <div>
                            <div className={styles.partnerName}>
                                🤝 Discrete Label
                            </div>
                            <div className={styles.partnerRole}>
                                - Immersive Exhibition Agency
                            </div>
                            <div
                                onClick={() => {
                                    window.open(
                                        'https://www.instagram.com/ran.dom.diver.sity/',
                                        '_blank'
                                    );
                                }}
                                className={styles.partnerLink}
                            >
                                <img src='assets/images/insta.png' />
                                @ran.dom.diver.sity
                            </div>
                        </div>
                        <div style={{ marginTop: 'calc(100vw * 0.08)' }}>
                            <div className={styles.partnerName}>
                                🤝 Cheon Che
                            </div>
                            <div className={styles.partnerRole}>
                                - Artist of Discrete Label
                            </div>
                            <div
                                className={styles.partnerLink}
                                onClick={() => {
                                    window.open(
                                        'https://www.instagram.com/cheon.che/',
                                        '_blank'
                                    );
                                }}
                            >
                                <img src='assets/images/insta.png' />
                                @cheon.che
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.folders}>
                    <div
                        className={styles.mobileContactButton}
                        onClick={() => navigate('/contact')}
                    >
                        <img src='assets/images/colorful-button.png' />
                        <div>CONTACT US</div>
                    </div>

                    <div
                        style={{
                            marginTop: `calc(100vw * ${folderMargin})`,
                        }}
                        onClick={() => navigate('/portfolio')}
                        className={styles.folder}
                    >
                        <img src='./assets/images/folder.png' />
                        <div className={styles.folderName}>Portfolio</div>
                    </div>

                    <div
                        style={{
                            marginTop: `calc(100vw * ${folderMargin})`,
                        }}
                        className={styles.folder}
                        onClick={() => navigate('/partner')}
                    >
                        <img src='./assets/images/folder.png' />
                        <div className={styles.folderName}>Partner</div>
                    </div>

                    <div
                        style={{
                            marginTop: `calc(100vw * ${folderMargin})`,
                        }}
                        className={styles.folder}
                        onClick={() => navigate('/about')}
                    >
                        <img src='./assets/images/documents.png' />
                        <div className={styles.folderName}>About</div>
                    </div>
                </div>
            </>
        );
    };

    return <Mobile address={'Partner'} content={content()} />;
}

export default PartnerMobile;
