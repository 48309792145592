import styles from './NotFoundMobile.module.css';
import Mobile from '../Mobile/Mobile';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

function NotFoundMobile() {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            var parent = document.getElementById(
                'mobileContent'
            ) as HTMLElement;

            var windowBg = document.getElementById('windowBg') as HTMLElement;

            if (!parent || !windowBg) return;

            let childHeight = windowBg.clientHeight;

            childHeight += window.innerWidth * 0.18;

            parent.style.height = childHeight + 'px';
        }, 500);
    }, []);

    const content = () => {
        return (
            <>
                <div className={styles.frameWrapper}>
                    <div className={styles.notFoundFrame}>
                        <img src='assets/images/not-found.png' />
                        <div className={styles.notFoundText}>
                            We could not find what you’ve been looking for.
                            <br />
                            Click button to see more interesting stuff!
                        </div>
                        <div
                            onClick={() => {
                                navigate('/');
                            }}
                            className={styles.button}
                        >
                            Go Home
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return <Mobile address={'404 NOT FOUND'} content={content()} />;
}

export default NotFoundMobile;
