import styles from './ContactMobile.module.css';
import Mobile from '../Mobile/Mobile';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

function ContactMobile() {
    const navigate = useNavigate();

    const [language, setLanguage] = useState('korean');
    const contact = require('../../../assets/json/contact.json').contact;
    const [contactContent, setContactContent] = useState(contact[language]);

    useEffect(() => {
        changeLangue(language);
    }, []);

    const reloadBg = (lang: string) => {
        setTimeout(() => {
            var parent = document.getElementById(
                'mobileContent'
            ) as HTMLElement;

            var windowBg = document.getElementById('contact') as HTMLElement;

            if (!parent || !windowBg) return;

            let childHeight = windowBg.clientHeight;

            childHeight += window.innerWidth * 0.3;

            parent.style.height = childHeight + 'px';
        }, 500);
    };

    const changeLangue = (lang: string) => {
        console.log(lang);
        setLanguage(lang);
        reloadBg(lang);

        setContactContent(contact[lang]);
        if (lang === 'korean') {
            document.getElementById('english')?.classList.add(styles.show);
            document.getElementById('english')?.classList.remove(styles.hidden);
            document.getElementById('korean')?.classList.remove(styles.show);
            document.getElementById('korean')?.classList.add(styles.hidden);
        } else {
            document.getElementById('english')?.classList.remove(styles.show);
            document.getElementById('english')?.classList.add(styles.hidden);
            document.getElementById('korean')?.classList.add(styles.show);
            document.getElementById('korean')?.classList.remove(styles.hidden);
        }
    };

    const content = () => {
        return (
            <>
                <div className={styles.contact} id='contact'>
                    <div className={styles.content}>
                        <div className={styles.contactContent}>
                            {contactContent.map((item: any, index: number) => {
                                return (
                                    <div
                                        key={index}
                                        className={`${item.type} ${
                                            language === 'korean'
                                                ? 'korean-font'
                                                : 'english-font'
                                        }`}
                                    >
                                        {item.content}
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            onClick={() => {
                                changeLangue(
                                    language === 'korean' ? 'english' : 'korean'
                                );
                            }}
                            className={styles.languageChangeButton}
                        >
                            <div id='korean' className={styles.korean}>
                                한글
                            </div>
                            <div id='english' className={styles.english}>
                                Abc
                            </div>
                        </div>
                    </div>

                    <div
                        style={{ marginTop: 'calc(100vw * 0.2)' }}
                        className={styles.contactInfo}
                    >
                        <div className={styles.infoTitle}>Email</div>
                        <div className={styles.infoContent}>
                            dobby@spade6.dev
                        </div>
                    </div>

                    <div
                        style={{ marginTop: 'calc(100vw * 0.1' }}
                        className={styles.contactInfo}
                    >
                        <div className={styles.infoTitle}>Based in</div>
                        <div className={styles.infoContent}>
                            Daejeon, South Korea
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return <Mobile address={'Contact Us'} content={content()} />;
}

export default ContactMobile;
