import styles from './Desktop.module.css';
import NavBar from '../../NavBar/NavBar';

import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function Desktop(props: any) {
    const navigate = useNavigate();

    return (
        <div className={styles.desktop}>
            <div className={styles.mainWindow}>
                <img src='assets/images/background.png' />
            </div>
            <div className={styles.navBar}>
                <NavBar />
            </div>
            <div className={`${styles.addressPos} address`}>
                {props.address}
            </div>

            {props.content}
        </div>
    );
}

export default Desktop;

Desktop.propTypes = {
    content: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
    ]),
    address: PropTypes.string.isRequired,
};
