import styles from './Partner.module.css';
import PartnerDesktop from '../../components/DesktopComponents/PartnerDesktop/PartnerDesktop';
import PartnerMobile from '../../components/MobileComponents/PartnerMobile/PartnerMobile';

const deviceWidth = window.innerWidth;

function Partner() {
    return deviceWidth > 768 ? <PartnerDesktop /> : <PartnerMobile />;
}

export default Partner;
